@media screen and (max-width: 991px) { 
    .hide-sidebar {
        width: 0 !important;
        padding: 0 !important;
    }
    .hide-sidebar * {
        width: 0 !important;
        padding: 0 !important;
    }
    .show-sidebar {
      position: absolute;
      z-index: 100;
      height: 100%;
    }
}

.main-sidebar .side--navbar{
    width: 250px;
    height: auto;
    padding-left: .5rem;
    padding-right: .5rem;
    margin-top: .5rem;
}

.main-sidebar .side--navbar--nav{
    width: 220px;
    position: absolute;
    top: 0;
}

.main-sidebar .side--navbar--nav .nav-link{
    margin-bottom: 3.2px;
    padding: 8px 16px;
    width: 234px;
    height: 40px;
    border-radius: .25rem;
    background-color: #343a40;
}

.main-sidebar .side--navbar--nav .show .nav-link{
    background-color: rgba(255,255,255,.1);
}

.main-sidebar .side--navbar--nav .show .dropdown--icon {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.main-sidebar .side--navbar--nav .show .dropdown-menu {
    max-height: -webkit-min-content;
    max-height: -moz-min-content;
    max-height: min-content;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.main-sidebar .side--navbar--nav .hide .dropdown-menu {
    max-height: 0;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.main-sidebar .side--navbar--nav .dropdown-menu{
    border: 0;
    background-color: #343a40;
    margin: 0;
    padding: 0;
}

.main-sidebar .side--navbar--nav .dropdown-menu .nav--drop--active{
    color: #343a40;
    background-color: white !important;

}

.main-sidebar .side--navbar--nav .dropdown-item{
    margin-bottom: 3.2px;
    padding: 8px 16px !important;
    width: 234px;
    height: 40px;
    color: rgba(255,255,255,.5);
    border-radius: .25rem;
    background-color: #343a40;
}
.main-sidebar .side--navbar--nav .dropdown-item:hover {
    background-color: rgba(255,255,255,.1);
}

.main-sidebar .brand--div {
    border-bottom: 1px solid #4b545c;
    padding: 0.8rem;
}
.main-sidebar .brand--text {
    font-size: 1.25rem !important;
    font-weight: 300 !important;
}


.main-sidebar .nav--item--active{
    background-color: #007bff !important;
    color: #fff !important;
}


@-webkit-keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

@-webkit-keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      opacity: 1;
    }
  }
  
  @keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      opacity: 1;
    }
  }

  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

@-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  
  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }

@-webkit-keyframes flipInX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transition-timing-function: ease-in;
      opacity: 0;
    }
    40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transition-timing-function: ease-in;
    }
    60% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1;
    }
    80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }
  
  @keyframes flipInX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transition-timing-function: ease-in;
      opacity: 0;
    }
    40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transition-timing-function: ease-in;
    }
    60% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1;
    }
    80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


  /* li > svg {} */
/* .nav-sidebar .menu-open > .nav-link i.right {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  } */