.ab--detail--progress {
    margin: 1% 2% 0 2%;
}

.ab--detail--title {
    margin: 0 2%;
    margin-bottom: 0;
    /* padding: 1.5% 2%; */
    background-color: white;
    box-shadow:0 0 5px rgba(0,0,0,.125), 0 5px 10px rgba(0,0,0,.2);
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
}

.ab--detail--title .title--top {
    display: flex;
    width: 100%;
}

.ab--detail--title .title--body {
    padding: 1rem 1rem;
    border: 1.5px solid darkgray;
    display: flex;
    align-items: center;
}

.ab--detail--content {
    /* display: flex;
    margin: 0 2%;
    margin-bottom: 0;
    width: 100%; */
    margin: 0 2%;
    margin-bottom: 0;
    /* padding: 1.5% 2%; */
    /* background-color: white; */
    /* box-shadow:0 0 5px rgba(0,0,0,.125), 0 5px 10px rgba(0,0,0,.2);
    border-radius: .25rem; */
    display: flex;
    flex-direction: column;
}
.ab--detail--content .content--table {
    box-shadow:0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    border-radius: .25rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
    min-height: 80px;
}

.ab--detail--content .content--table .content--table--top {
    display: flex;
    align-items: center;
    padding: .5rem;
}

.ab--detail--content .content--table .table {
    margin: 0;
}