@media (min-width: 768px) {
    #daterangepicker {
        left: -128% !important;
    }
}

@media (max-width: 767px) {
    #daterangepicker{
        flex-direction: column;
        left: 2% !important
    }
    
    #daterangepicker .rangecontainer {
        width: 100%;
        text-align: center;
    }
}

.multipleContentOnLine {
    position: relative;
    display: inline;
    padding: 1px;
}
.leftChevron {
    display: grid;
    width: 25%;
    padding: 2px;
    justify-content: left;
    font-size: 14px;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.rightChevron {
    display: grid;
    width: 25%;
    padding: 2px;
    justify-content: right;
    font-size: 14px;
}

.timeContainer {
    display: flex;
    justify-content: center;
}

.inputDate {
    text-align: center;
}

.input-group-addon:first-child {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.calendarAddon {
    padding: 0 ;
    width: 40px ;
    display: flex ;
    justify-content: center ;
    align-items: center ;
}

.input-group-addon {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.font-calender {
    width: 15px ;
    height: 20px ;
    display: flex;
    justify-content: center;
    align-items: center;
}
.font-calender svg{
    width: 100% !important;
    height: 100% ;
}

.input-group-addon, .input-group-btn {
    white-space: nowrap;
    vertical-align: middle;
    background-color: inherit;

}


.input-group {
    position: relative;
    display: flex;
    border-collapse: separate;
}

.font-clock {
    position: absolute;
    top: 8px;
    left: 14px;
}

