.test{
    flex-flow: row nowrap;
    justify-content: center;
    position: relative;
    display: flex;
    padding: .5rem 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.nav--container .dropdown-menu {
    position: absolute !important;
}

.progress-div{
    width: 100%;
    height: 5px;
    background-color: rgba(169,169,169,0.5);
}
.progress{
    width: 0px;
    height: 5px !important;
}
.progress-bar{
    width: 100%
}

