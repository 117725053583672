.rec--progress {
    margin: 1% 2% 0 2%;
}

.rec--create--title {
    margin: 0 2%;
    margin-bottom: 0;
    padding: 1.5% 2%;
    background-color: white;
    box-shadow:0 0 5px rgba(0,0,0,.125), 0 5px 10px rgba(0,0,0,.2);
    border-radius: .25rem;
}

.rec--con--box {
    display: flex;
    margin-left: 2%;
}

.rec--con--line{
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
}

.rec--line {
    height: 30px;
    border-right: 1.5px solid darkgray;
}

.rec--circle{
    width: 20px;
    height: 20px;
    border: 1.5px solid darkgray;
    border-radius: .6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rec--line2 {
    height: 20px;
    border-right: 1.5px solid darkgray;
}

.rec--circle2{
    height: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
}

.rec--font {
    color: darkslategrey;
    z-index: 2;
}

.rec--line3 {
    right: 0;
    width: 40%;
    height: 17px;
    border-bottom: 1.5px solid darkgray;
    position: absolute;
    z-index: 1;
}

.recommendation--container {
    height: 100%;
    width: 92.8%;
    margin: 4% 0;
    background-color: white;
    box-shadow:0 0 5px rgba(0,0,0,.125), 0 5px 10px rgba(0,0,0,.2);
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
}

.recommendation--container .rec--header {
    padding: 1% 2% 2% 2%;
    box-shadow:0 4px 5px rgba(0,0,0,.125);
}

.recommendation--container .rec--body {
    padding: 2% 2%;
}

.recommendation--container .rec--header--div{
    width: 25px;
    height: 25px;
    border: solid 1px darkgray;
    background-color: darkgray;
    color: white;
    border-radius: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1%;
}

.recommendation--container .now--experience{
    background-color: #007bff;
}

.recommendation--container .rec--footer {
    width: 100%;
    height: 100%;
    padding: 1% 0;
    box-shadow: 0 -2px 5px rgba(0,0,0,.125);
}

.recommendation--container .now--hide {
    display: none;
}

.recommendation--container .rec--footer .btn {
    box-shadow: 0 3px 2px rgba(0,0,0,.125);
}

.recommendation--container .btn--box {
    width: 200px;
    box-shadow: 5px 5px 3px rgba(0,0,0,.125);
    padding: 1% 2%;
}

.recommendation--container .btn--box .dropdown-menu{
    top: 5px !important;
    left: -8px !important;
    padding: 0;
}

.recommendation--container .btn--box .dropdown-menu .dropdown-item {
    padding: 2% 5%;
}


.recommendation--container .btn--box .btn:focus {
    box-shadow: none;
}

.recommendation--container .btn--algo {
    padding: 0;
    width: 100%;
    margin: 0;
}


.recommendation--container .btn--algo div {
    text-align: left;
    width: 90%;
    color: white;
    font-weight: 340;
}

.recommendation--container .btn--box .dropdown-toggle::after {
    margin-top: 8px;
    color: white;
}

.recommendation--container .categoris--menu{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
}

.recommendation--container .categoris--item{
    margin-right: 0.5%;
    margin-bottom: 0.5%;
}

