/* html, body, #root{
    width: 100%;
    height: auto;
} */
html {
    height: auto;
    width: 100%;
}

body {
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

.pointer {
    cursor: pointer;
}

.dropdown-menu {
    max-height: 200px;
    overflow: auto;
}

table td {
    vertical-align: middle !important;
}

.react-bootstrap-table .w-8 {
    width: 8% !important;
}
.react-bootstrap-table .w-16 {
    width: 16% !important;
}
.react-bootstrap-table .over--split {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
