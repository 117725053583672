.ab--create--progress {
    margin: 1% 2% 0 2%;
}

.ab--create--title {
    margin: 0 2%;
    margin-bottom: 0;
    padding: 1.5% 2%;
    background-color: white;
    box-shadow:0 0 5px rgba(0,0,0,.125), 0 5px 10px rgba(0,0,0,.2);
    border-radius: .25rem;
}

.ab--create--con {
    display: flex;
    margin-left: 2%;
}

.ab--create--con .ab--con--line{
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
}

.ab--create--con .ab--line {
    height: 30px;
    border-right: 1.5px solid darkgray;
}

.ab--create--con .ab--circle{
    width: 20px;
    height: 20px;
    border: 1.5px solid darkgray;
    border-radius: .6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ab--create--con .ab--line2 {
    height: 20px;
    border-right: 1.5px solid darkgray;
}

.ab--create--con .ab--circle2{
    height: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
}

.ab--create--con .ab--font {
    color: darkslategrey;
    z-index: 2;
}

.ab--create--con .ab--line3 {
    right: 0;
    width: 40%;
    height: 17px;
    border-bottom: 1.5px solid darkgray;
    position: absolute;
    z-index: 1;
}

.ab--create--con .ab--container {
    height: 100%;
    width: 92.8%;
    margin: 4% 0;
    background-color: white;
    box-shadow:0 0 5px rgba(0,0,0,.125), 0 5px 10px rgba(0,0,0,.2);
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
}

.ab--create--con .ab--container .ab--header {
    padding: 1% 2% 2% 2%;
    box-shadow:0 4px 5px rgba(0,0,0,.125);
}

.ab--create--con .ab--container .ab--body {
    padding: 2% 2%;
}

.ab--create--con .ab--container .ab--header--div{
    width: 25px;
    height: 25px;
    border: solid 1px darkgray;
    background-color: darkgray;
    color: white;
    border-radius: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1%;
}

.ab--create--con .ab--container .now--experience{
    background-color: #007bff;
}

.ab--create--con .ab--container .ab--footer {
    width: 100%;
    height: 100%;
    padding: 1% 0;
    box-shadow: 0 -2px 5px rgba(0,0,0,.125);
}

.ab--create--con .ab--container .now--hide {
    display: none;
}

.ab--create--con .ab--container .ab--footer .btn {
    box-shadow: 0 3px 2px rgba(0,0,0,.125);
}

.ab--create--con .ab--container .setting--box {
    min-width: 250px;
    height: 100px;
    box-shadow: 0 0 5px rgba(0,0,0,.4);
    padding: 1% 0.7%;
}

.ab--create--con .ab--container .btn--box {
    min-width: 200px;
    box-shadow: 5px 5px 3px rgba(0,0,0,.125);
    padding: 1% 2%;
}

.ab--create--con .ab--container .btn--box .dropdown-menu{
    top: 5px !important;
    left: -8px !important;
    padding: 0;
}

.ab--create--con .ab--container .btn--box .dropdown-menu .dropdown-item {
    padding: 2% 5%;
}


.ab--create--con .ab--container .btn--box .btn:focus {
    box-shadow: none;
}

.ab--create--con .ab--container .btn--algo {
    padding: 0;
    width: 100%;
    margin: 0;
}


.ab--create--con .ab--container .btn--algo div {
    text-align: left;
    width: 90%;
    color: white;
    font-weight: 340;
}

.ab--create--con .ab--container .btn--box .dropdown-toggle::after {
    margin-top: 8px;
    color: white;
}

.ab--create--con .ab--container .categoris--menu{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
}

.ab--create--con .ab--container .categoris--item{
    margin-right: 0.5%;
    margin-bottom: 0.5%;
}

.ab--create--con .ab--container .count--container {
    height: 100px;
}

.ab--create--con .ab--container .count--box--con {
    margin-top:3.5px;
    background-color: #f8f9fa;
    border: 1px solid rgba(122,122,122,.6);
    border-radius: .25rem;
    width: 100px;
    height: 60px;
    box-shadow: 0 0 5px rgba(0,0,0,.2)
}

.ab--create--con .ab--container .count--box {
    background-color: #dbdcdd;
    /* border-right: 1px solid rgba(122,122,122,.6); */
}

.ab--create--con .ab--container .count--box:hover {
    background-color: #cccccc;
    cursor: pointer;
}
.ab--create--con .ab--container .count--box:active {
    background-color: #aaaaaa;
    box-shadow: 0 0 3px #aaaaaa;
    transform: translateY(0.3px);
}

.ab--create--con .ab--container .count--input {
    text-align: center;
    width: 100%;
    height: inherit;
    border: 0;
    background-color: inherit;
}

.ab--create--con .ab--container .card--line {
    color : rgba(122,122,122,.6);
}