.Register {
    display: flex;
    flex-direction: column;
    height: 800px;
    /* width: 100%; */
    align-items: center;
    justify-content: center;
    margin: auto;
}

.Register form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 600px;
    height: 70%;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f2f2f2;
}

.Register .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15%;
}

.Register .mid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 55%;
    width: 80%;
}
.Register .mid--info {
    display: flex;
    flex-direction: column;
    height: 33%;
    width: 100%;
    justify-content: space-evenly;
}
.Register label {
    margin-bottom: 1%;
}
.Register .mid--info label {
    height: 25%;
    font-size: large;
}
.Register .mid--info input {
    padding: 2% 4%;
    display: inline-block;
    height: 45%;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.Register .bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20%;
    width: 80%;
    /* border: gray 1px solid */
}
.Register .bot input {
    height: 35px;
    width: 100%;
    background-color: rgb(91, 189, 96);
    color: white;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 120%;
}

.Register .bot p {
    height: 40%;
    width: 100%;
    text-align: right;
    margin: 0.5%;
    font-size: 12px;
}