.page--flow--container{
    width: 100%;
    height: 100%;
}

.flow--header {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1%;
    background-color: white;
}

.selection--seg, .add--seg{
    width: 350px;
    height: 60px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    padding-left: 1%;
    margin-right: 1%;
}

.flow--body{
    width: 100%;
    height: 500px;
    /* overflow: scroll; */
}