.lugstay--custom .box-one {
    height: 7rem;
    position: relative;
    z-index: 0;
    box-shadow: 0 0 5px darkgray;
}

.lugstay--custom .box-two {
    height: 7rem;
    position: absolute;
    top: 0;
    left: 0;
}

.lugstay--custom .box-three {
    background-color:rgba(0, 0, 0, 0) !important;
    color: rgba(0, 0, 0, 0)!important;
    height: 100px;
    width: inherit;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
}
.lugstay--custom .box-three div {
    background-color:rgba(0, 0, 0, 0) !important;
    color: rgba(0, 0, 0, 0)!important;
}

.lugstay--custom .box-three:hover {
    background-color:rgba(0, 0, 0, .7) !important;
    color: rgba(255, 255, 255, 1)!important;
}
.lugstay--custom .box-three:hover div {
    background-color:rgba(255, 255, 255, .7) !important;
    color: rgba(0, 0, 0, 1)!important;
}
.lugstay--custom .progress {
    height: 2rem !important;
}
.lugstay--custom .inner--card {
    box-shadow: -10px 10px 10px darkgrey !important;
}
.lugstay--custom .selection--box {
    border: 5px solid rgb(206, 47, 47);
}
.segment-dropdown-menu {
    transform: translate3d(0, 37px, 0px) !important;
}