.dashboard--container{
    width: 100%;
    height: 100%;
    width: 100%;
    padding: 0 !important;
    position: relative;
    display: flex;
}

.content--wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}