.card {
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    margin-bottom: 1rem;
}

.card-header{
    background-color: white !important;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.info-box{
    box-shadow:0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    border-radius: .25rem;
    background-color: #fff;
    display: flex;
    margin-bottom: 1rem;
    min-height: 80px;
    padding: .5rem;
    position: relative;
    width: 100%;
}

.info-box .info-box-icon{
    border-radius: .25rem;
    align-items: center;
    display: flex;
    font-size: 1.875rem;
    justify-content: center;
    text-align: center;
    width: 70px;
}

.bg-info, .bg-info>a {
    color: #fff !important;
}
.bg-danger, .bg-danger>a {
    color: #fff !important;
}
.bg-success, .bg-success>a {
    color: #fff !important;
}

.elevation-1{
    box-shadow: 0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)!important;
}

.info-box .info-box-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 120%;
    flex: 1;
    padding: 0 10px;
}

.info-box .info-box-text, .info-box .progress-description {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.info-box .info-box-number{
    display: block;
    margin-top: .25rem;
    font-weight: 700;
}