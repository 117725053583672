#dropmenu-2 {
    display: none 
}

.menu2show {
    display: inline-block !important;
}

input:focus, button:focus{
    outline: none !important;
}

.btn-sm {
    padding: 0.055rem 0.5rem !important;
    height: 25px;
    width: 40px;
}
.drop--input{
    border: 0;
    width: 140px;
}
.drop--toggle{
    border: 0;
}
.drop--div{
    border-bottom: 1px solid gray;
    margin-right: 5px;
    height: auto;
    width: 170px;
}

.and-box {
    display: none;
    width: 50px;
    height: inherit;
    min-height: 35px;
}

.big-and{
    display: none;
    width: 50px;
}

.container--div{
    height: 100%;
    width: 100%;
}

.move--or {
    position: relative;
    left: -35px;
    top: 23px;
}
.move--or small {
    display: none;
}