.ab--result--mid .react-bootstrap-table table {
    table-layout: unset !important;
}

.ab--result--mid .active .page-link{
    background-color: #337ab7 !important;
    border-color: #337ab7 !important;
}

.ab--result--mid .dropdown-menu li a {
    color: #333;
}

.ab--result--mid .show #pageDropDown {
    background-color: #ccc;
    border: 1px solid #ccc ;
}
.ab--result--mid #pageDropDown {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}
.ab--result--mid .react-bootstrap-table .expand-cell {
    text-align: center;
}
.react-bootstrap-table-editing-cell {
    text-align: center;
}


.rec--result--top {
    padding: 2%;
    background-color: white;
    box-shadow:0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    margin-bottom: 1rem;
    min-height: 80px;
    position: relative;
    width: 100%;
}

.rec--result--mid {
    box-shadow:0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    border-radius: .25rem;
    background-color: #fff;
    display: flex;
    margin: 2%;
    margin-top: 1%;
    min-height: 80px;
    padding: .5rem;

}

.rec--result--mid .expand-cell-header {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.rec--result--mid .expand-cell {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}