.node rect {
    fill-opacity: .9;
    shape-rendering: crispEdges;
  }

  .node text {
    font: 10px sans-serif;
    pointer-events: none;
    text-shadow: 0 1px 0 #fff;
  }

  .link {
    fill: none;
    stroke: darkblue;
    stroke-opacity: .2;
  }

  .link:hover {
    stroke-opacity: .5;
  }