.Login {
    display: flex;
    flex-direction: column;
    height: 800px;
    /* width: 100%; */
    align-items: center;
    justify-content: center;
    margin: auto;
}

.Login form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 600px;
    height: 60%;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f2f2f2;
}

.Login .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15%;
}

.Login .mid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 45%;
    width: 80%;
}
.Login .mid--email {
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 100%;
    justify-content: space-evenly;
}
.Login .mid--email label {
    height: 25%;
    font-size: large;
}
.Login .mid--email input {
    padding: 2% 4%;
    display: inline-block;
    height: 40%;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.Login .mid--pwd {
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 100%;
    justify-content: space-evenly
}
.Login .mid--pwd label {
    height: 25%;
    font-size: large;

}
.Login .mid--pwd input {
    padding: 2% 4%;
    display: inline-block;
    height: 40%;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.Login .bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30%;
    width: 80%;
    /* border: gray 1px solid */
}
.Login .bot input {
    height: 25%;
    width: 100%;
    background-color: rgb(91, 189, 96);
    color: white;
    margin: 5px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 120%;
}
.Login .bot input[type=submit]:hover {
    background-color: #3d9940;
}

.Login .bot div {
    display: flex;
    height: 35px;
    width: 100%;
}

.Login .bot p {
    height: 25%;
    width: 100%;
    text-align: right;
    margin: 3%;
    
    font-size: 12px;
}